<template>
    <div class="promise">
        <StackRouterHeaderBar :showTitle="true" :title="$translate('MAKE_SCHEDULE')" />
        <section class="section m-t-24">
            <div class="title">
                <div class="text" v-html="$translate(date.title)" />
            </div>
            <div class="content">
                <div class="desc" v-html="$translate(date.desc)" />
                <div class="items">
                    <div v-for="(val, idx) in dateContent" :key="idx">
                        <div
                            class="item date"
                            :class="{ selected: selectedDate === idx, warn: reschedule && impossible(idx) }"
                            @click="onClickCalendar(idx)"
                        >
                            <i class="material-icons-outlined">calendar_today</i>
                            <div v-if="val === null">{{ `일정 선택(월-일, 시간)${idx > 2 ? '(선택사항)' : ''}` }}</div>
                            <div class="val" v-else>{{ val.parsed }}</div>
                        </div>
                        <!-- 여성재선택의 경우 warn 클래스 추가 -->
                        <div v-if="reschedule && impossible(idx)" class="warn">
                            *남성분이 불가능한 일정입니다. 다른 일정을 선택해주세요!
                        </div>
                    </div>
                    <div @click="alternativeDate" class="item date" v-if="!isAtype">
                        <i class="material-icons-outlined">calendar_today</i>
                        <div class="val">가능한 일정이 없어요!</div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="title">
                <div class="text" v-html="$translate(activity.title)" />
            </div>
            <div class="content">
                <div class="desc" v-html="$translate(activity.desc)" />
                <div class="grid items" :class="{ isBtype: !isAtype }">
                    <div
                        class="item"
                        v-for="item in activity.content"
                        :key="item.name"
                        :class="{ selected: item.selected }"
                        @click="selectActivity(item.id)"
                        v-show="isAtype || item.id !== 3"
                    >
                        <span v-html="$translate(item.name.toUpperCase())" />
                    </div>
                </div>
                <div v-if="!isAtype" class="type-b m-t-8">
                    <div class="c-primary desc-sm">
                        여성분의 선호 방식 : {{ $translate(content.meet_type.toUpperCase()) }}
                    </div>
                    <div class="c-red desc-sm">
                        여성분이 꺼리는 음식 :
                        {{ content.foods_unpreferred_status === 0 ? '다 잘먹어요' : content.foods_unpreferred }}
                    </div>
                    <div class="desc-sm m-t-8 flex-row">
                        <div class="m-r-4">*</div>
                        <span
                            >즐거운 만남을 위해 여성분이 선호하는 만남 방식에 맞춰주시길 바랍니다. 지역은 회원님이 선택
                            가능합니다.</span
                        >
                    </div>
                </div>
            </div>
            <div
                v-if="(isAtype && activity.content[0].selected) || activity.content[2].selected"
                class="content m-t-16"
            >
                <div class="desc" v-html="'꺼리는 음식이 있으신가요?'" />
                <div
                    class="item"
                    v-for="item in cannotEat.content"
                    :key="item.name"
                    :class="{ selected: item.selected }"
                    @click="selectCannotEat(item.id)"
                    v-show="item.id !== 2"
                >
                    <span v-html="$translate(item.name.toUpperCase())" />
                </div>
                <TextareaWithX
                    v-model="foodsUnpreferred"
                    class="item input"
                    isInputMode="true"
                    placeholder="ex. 회 종류는 못먹어요."
                    v-show="cannotEat.content[1].selected"
                />
            </div>
        </section>
        <section v-if="!isAtype" class="section">
            <div class="title">
                <div class="text" v-html="`지역 선택`" />
            </div>
            <div class="content">
                <div class="desc m-b-8">만날 지역을 '한 곳'만 특정해서 입력해주세요.</div>
                <TextareaWithX
                    v-model="place"
                    class="m-b-8"
                    isInputMode="true"
                    placeholder="ex. 합정(O), 합정이나 한남 다 괜찮음(X)"
                />
                <div class="desc-sm desc-flex">
                    <div class="m-r-4">*</div>
                    <span>중간 지역 혹은 상대에게 조금 더 가까운 지역으로 고르는걸 추천드려요.</span>
                </div>
                <!-- <div class="desc-sm desc-flex">
                    <div class="m-r-4">*</div>
                    <span
                        >구체적인 식당(카페)은 나중에 예약하시고, 만남 전날 대화방이 열리게 되면 그 때 공유해주시면
                        됩니다.</span
                    >
                </div> -->
            </div>
        </section>
        <section v-if="!isAtype" class="section">
            <div class="title">
                <div class="text" v-html="`장소 예약`" />
            </div>
            <div class="content">
                <div class="desc m-b-8"><b>매니저가 만남 장소를 예약</b>해드릴 예정입니다.</div>
                <div class="grey-container">
                    <div class="desc-grey desc-flex">
                        <!-- <div class="m-r-4">*</div> -->
                        <span>장소 예약 이렇게 해드려요.</span>
                    </div>
                    <div class="desc-black flex-row m-t-8">
                        <!-- <div class="desc-icon">*</div> -->
                        <img class="desc-icon" :src="require(`@/assets/images/promise-page/restaurant.png`)" alt="" />
                        <span
                            >식사의 경우 2인 총합 예산 5~7만원 내외의 파스타집을 예약해드립니다.(파스타 2 + 음료 2 기준)
                            예약이 불가한 경우 식사 장소만 안내드릴 수 있습니다.</span
                        >
                    </div>
                    <div class="desc-black flex-row m-t-8">
                        <img class="desc-icon" :src="require(`@/assets/images/promise-page/coffee.png`)" alt="" />
                        <span
                            >커피의 경우 예약이 가능한 카페 위주로 예약해드립니다. 단, 예약을 받지 않는 카페가
                            대부분이어서 장소만 안내드릴 수 있습니다.</span
                        >
                    </div>
                    <div class="desc-black flex-row m-t-8">
                        <img class="desc-icon" :src="require(`@/assets/images/promise-page/check.png`)" alt="" />
                        <span
                            >매니저가 예약해드린 장소는 부득이한 경우가 아니라면 변경이 불가합니다. 개인적으로 선호하는
                            분위기나 메뉴가 있으신 분들은 직접 예약을 진행해주세요.</span
                        >
                    </div>
                </div>
                <div class="item check-box" :class="{ selected: selfReservation }" @click="selectSelfReservation()">
                    <!-- <div class="check-box"> -->
                    <img
                        class="check-img"
                        v-if="selfReservation || this.me.my_manager === 6"
                        :src="require('@/assets/images/select-active.png')"
                        alt=""
                    />
                    <img class="check-img" v-else :src="require(`@/assets/images/select-inactive.png`)" alt="" />
                    <!-- </div> -->
                    <span v-html="'제가 직접 장소를 정하고 싶어요'" />
                </div>
                <div class="desc-sm desc-flex m-t-4">
                    <div class="m-r-4">*</div>
                    <span>예약된 장소가 입력되면 ‘약속관리'탭에서 확인하실 수 있습니다.</span>
                </div>
            </div>
        </section>
        <BottomButton @click="submit" :disabled="disabled" :label="$translate('FINISH_SELECT')" />
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'PromisePage',
    props: ['message', 'isAtype', 'userInfo', 'reschedule'],
    mounted() {
        if (!this.isAtype) {
            this.dateContent = []
            for (let i = 1; i <= 5; i++) {
                const ymd = this.$moment(this.content[`date_${i}`]).format('YY/MM/DD HH:mm')
                const day = this.$moment(this.content[`date_${i}`]).format('dddd').toUpperCase()
                if (this.content[`date_${i}`])
                    this.dateContent.push({
                        original: this.content[`date_${i}`],
                        parsed: `${ymd} ${this.$translate(day)}`,
                    })
            }
        } else if (this.reschedule) {
            this.dateContent = []
            for (let i = 1; i <= 5; i++) {
                const ymd = this.$moment(this.content[`date_${i}`]).format('YY/MM/DD HH:mm')
                const day = this.$moment(this.content[`date_${i}`]).format('dddd').toUpperCase()
                if (this.content[`date_${i}`])
                    this.dateContent.push({
                        original: this.content[`date_${i}`],
                        parsed: `${ymd} ${this.$translate(day)}`,
                    })
            }
            this.impossibleDate = []
            for (let i = 1; i <= 5; i++) {
                const ymd = this.$moment(this.content[`date_${i}`]).format('YY/MM/DD HH:mm')
                const day = this.$moment(this.content[`date_${i}`]).format('dddd').toUpperCase()
                if (this.content[`date_${i}`])
                    this.impossibleDate.push({
                        original: this.content[`date_${i}`],
                        parsed: `${ymd} ${this.$translate(day)}`,
                    })
            }
        }
    },
    methods: {
        async alternativeDate() {
            const idx = await this.$modal.custom({
                component: 'ModalVerticalButtons',
                options: {
                    title: '가능한 일정이 없으신가요?',
                    // body: `여성분에게 가능한 일정에 대해서 알려주세요. 혹시 날짜는 맞는데 시간이 안된다면 가능한 날짜로 선택해주세요. 대화를 통해 조율가능합니다.`,
                    body: `여성분에게 직접 메시지를 보내 일정을 조율해보세요.`,
                    buttons: [
                        {
                            label: '일정 조율 메시지 보내기',
                            style: {
                                bgColor: '#04C9B2',
                                color: '#FFFFFF',
                                border: 'none',
                            },
                        },
                        {
                            label: '나중에 조율하기',
                            style: {
                                bgColor: '#FFFFFF',
                                color: '#04C9B2',
                                border: `1px solid #04C9B2`,
                            },
                        },
                    ],
                    height: '48px',
                },
            })
            if (idx === 0) {
                // this.$stackRouter.pop()
                this.$stackRouter.push({
                    name: 'RescheduleMessagePage',
                    props: {
                        // person: this.content.name,
                        message: this.message,
                        scheduleId: this.content.schedule_id,
                        // targetId: this.content.source_id,
                        type: 'send_reschedule_message',
                    },
                })
            } else {
            }
        },
        selectActivity(id) {
            this.activity.content = this.activity.content.map(c => ({
                ...c,
                selected: c.id === id,
            }))
        },
        selectCannotEat(id) {
            this.cannotEat.content = this.cannotEat.content.map(c => ({
                ...c,
                selected: c.id === id,
            }))
        },
        async selectSelfReservation() {
            if (this.selfReservation === true) {
                this.selfReservation = !this.selfReservation
            } else if (this.me.my_manager === 6) {
                //  체크해제시 얼럿
                await this.$modal.basic({
                    body: `AI 상품의 경우 매니저의 장소 예약 서비스를 제공드리기 어렵습니다. 약속장소는 가급적 직접 정해주시길 부탁드립니다.`,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else {
                const idx = await this.$modal.basic({
                    body: `약속 3일전까지 만남 장소를 정한 후 장소를 입력해주세요.예약이 필요한 경우 꼭 예약을 완료한 후에 입력해주세요. (다음 화면에서 입력이 가능합니다.)`,
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })

                if (idx === 1) {
                    this.selfReservation = !this.selfReservation
                }
            }
        },
        async onClickCalendar(idx) {
            if (this.isAtype) {
                const res = await this.$modal.custom({
                    component: 'ModalSelectDate',
                })
                if (res) {
                    const { y, m, d, time } = res
                    const originalDate = `${y}-${m}-${d} ${time}`
                    const ymd = this.$moment(originalDate).format('YY/MM/DD')
                    const day = this.$moment(originalDate).format('dddd').toUpperCase()
                    const hourMinute = this.$moment(originalDate).format('A h:mm')

                    let submit = false
                    this.dateContent = this.dateContent.map((date, i) => {
                        if (date && idx !== i) return date
                        if (!submit) {
                            submit = true
                            return {
                                original: originalDate,
                                parsed: `${ymd} ${this.$translate(day)}, ${hourMinute}`,
                            }
                        } else return null
                    })
                    if (!this.reschedule) {
                        this.dateContent.sort((a, b) =>
                            ((b || {}).original || 0) > ((a || {}).original || 0) ? -1 : 1
                        )
                    }
                }
            } else {
                this.selectedDate = idx
            }
        },
        preparedPayload() {
            const messagePayload = this.isAtype ? 'a_message_id' : 'b_message_id'
            const payload = {}
            payload[messagePayload] = this.message.id

            if (this.isAtype) {
                payload.meet_type = this.selectedActivity.name
                this.dateContent.forEach((date, i) => {
                    if (date) payload[`date_${i + 1}`] = date.original
                })
                if (this.selectedCannotEat) {
                    payload.foods_unpreferred_status = this.selectedCannotEat.id
                    payload.foods_unpreferred = this.foodsUnpreferred
                }
            } else {
                payload.meet_type_confirmed = this.selectedActivity.name
                payload.date_confirmed = this.content[`date_${this.selectedDate + 1}`]
                payload.meet_place = this.place
                if (this.me.my_manager === 6) {
                    payload.self_reservation = 1
                } else {
                    payload.self_reservation = this.selfReservation ? 1 : 0
                }
            }
            return payload
        },
        async goModalPayTicket() {
            await this.$modal.custom({
                component: 'ModalPayTicket',
                options: {
                    userInfo: {
                        ...this.userInfo,
                        ...this.preparedPayload(),
                    },
                },
            })
        },
        async submit() {
            // 남성이랑 여성 만나서 뭐할지 다를 경우 약속 정하기 제출 불가
            if (!this.isAtype) {
                const goBack = () => {
                    if (this.content.meet_type === 'both') return false
                    else {
                        return this.content.meet_type !== this.selectedActivity.name
                    }
                }
                if (goBack()) {
                    this.$modal.basic({
                        body:
                            '즐거운 만남을 위해 여성분이 선호하는 만남 방식에 맞춰주시길 바랍니다. 지역은 회원님이 선택 가능합니다.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    return
                }
            }

            const payload = this.preparedPayload()
            let idx
            if (this.isAtype) {
                idx = await this.$modal.custom({
                    component: 'ModalScheduleAlert',
                    options: { payload },
                })
            } else {
                // const today = this.$moment().add(1, 'days')
                const closedDay = this.$moment().add(2, 'days')
                const day = this.$moment(payload.date_confirmed)
                // if (this.$moment(today).format('YY/MM/DD') === this.$moment(day).format('YY/MM/DD')) {
                //     idx = await this.$modal.basic({
                //         title: '내일 만나시려는 것이 맞나요?',
                //         body: `<b>'${this.$moment(payload.date_confirmed).format(
                //             'MM월 DD일 HH시 mm분'
                //         )} ${this.$translate(this.$moment(payload.date_confirmed).format('dddd').toUpperCase())} ${
                //             payload.meet_place
                //         }'</b>(으)로 약속을 정하시겠어요?<br>약속까지 24시간이 안남았을 경우 취소/변경이 어렵습니다.`,
                //         buttons: [
                //             {
                //                 label: 'CANCEL',
                //                 class: 'btn-default',
                //             },
                //             {
                //                 label: 'CONFIRM',
                //                 class: 'btn-primary',
                //             },
                //         ],
                //     })
                if (
                    !this.selfReservation &&
                    this.$moment(closedDay).format('YY/MM/DD') >= this.$moment(day).format('YY/MM/DD')
                ) {
                    idx = await this.$modal.basic({
                        // title: '내일 만나시려는 것이 맞나요?',
                        body: `약속 일정이 3일 이내인 경우, 매니저가 장소 예약 서비스를 제공하지 않습니다. 최대한 빨리 장소를 정하셔서 장소를 입력해주세요.`,
                        buttons: [
                            // {
                            //     label: 'CANCEL',
                            //     class: 'btn-default',
                            // },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    this.selfReservation = true
                    return
                } else {
                    idx = await this.$modal.basic({
                        body: `<b>'${this.$moment(payload.date_confirmed).format(
                            'MM월 DD일 HH시 mm분'
                        )} ${this.$translate(this.$moment(payload.date_confirmed).format('dddd').toUpperCase())} ${
                            payload.meet_place
                        }'</b>(으)로 약속을 정하시겠어요?<br>변경이 어려우니 다시 한번 확인해주세요.`,
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (this.me.my_manager === 6) {
                        await this.$modal.basic({
                            body: `약속 3일전까지 만남 장소를 정한 후 장소를 입력해주세요.예약이 필요한 경우 꼭 예약을 완료한 후에 입력해주세요. (다음 화면에서 입력이 가능합니다.)`,
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                    }
                }
            }

            if (!idx) return

            try {
                const { res } = await scheduleService.createSchedule(this.content.schedule_id, payload)
                if (
                    [
                        'start_b',
                        'entered_type',
                        'entered_place',
                        'restart_a',
                        'reentered_type',
                        'restart_b',
                        'confirmed_b',
                    ].includes(res)
                ) {
                    await this.$store.dispatch('loadChat', { chatId: this.message.chat_id, force: true })

                    // if (!this.isAtype) this.goModalPayTicket()
                    // if (!this.isAtype) this.clickSubmit()

                    this.$stackRouter.pop()
                } else {
                    this.$toast.error(res)
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
    },
    data: () => ({
        selectedDate: null,
        place: null,
        dateContent: [null, null, null, null, null],
        impossibleDate: [null, null, null, null, null],
        activity: {
            title: 'SELECT_ACTIVITY',
            desc: 'SELECT_ACTIVITY_DESC',
            content: [
                {
                    id: 1,
                    name: 'meal',
                    selected: false,
                },
                {
                    id: 2,
                    name: 'coffee',
                    selected: false,
                },
                {
                    id: 3,
                    name: 'both',
                    selected: false,
                },
            ],
        },
        cannotEat: {
            content: [
                {
                    id: 0,
                    name: '다 잘먹어요',
                    selected: true,
                },
                {
                    id: 1,
                    name: '못 먹는 메뉴/선호하지 않는 메뉴가 있어요',
                    selected: false,
                },
                {
                    id: 2,
                    name: '',
                    selected: false,
                },
            ],
        },
        foodsUnpreferred: null,
        selfReservation: false,
    }),
    computed: {
        date() {
            return {
                title: 'SELECT_DATE',
                desc: this.isAtype ? 'SELECT_DATE_DESC_A' : 'SELECT_DATE_DESC_B',
            }
        },
        selectedActivity() {
            return this.activity.content.find(c => c.selected) || false
        },
        selectedCannotEat() {
            return this.cannotEat.content.find(c => c.selected) || false
        },
        disabled() {
            if (this.isAtype) {
                // 불가능한 날짜를 포함하지 않는 날짜 3개 이상일 경우에 버튼 활성화
                let isDateSubmit = ''
                if (this.reschedule) {
                    isDateSubmit =
                        this.dateContent.filter(
                            d =>
                                d !== null &&
                                !this.impossibleDate.some(date => this.$moment(date.original).isSame(d.original))
                        ).length >= 3
                } else {
                    isDateSubmit = this.dateContent.filter(d => d !== null).length >= 3
                }

                if (this.selectedActivity) {
                    if (this.selectedActivity.id === 1) {
                        return !(isDateSubmit && this.selectedActivity)
                    } else {
                        if (this.selectedCannotEat) {
                            return !isDateSubmit
                        } else {
                            return !(isDateSubmit && this.cannotEat.content[2].name !== '')
                        }
                    }
                } else {
                    return true
                }
            } else {
                return !(this.selectedDate !== null && this.selectActivity && this.place)
            }
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        impossible() {
            return idx => {
                if (!this.isAtype) return false
                if (!(this.dateContent[idx] || {}).original) return false
                return this.impossibleDate.some(date =>
                    this.$moment(date.original).isSame(this.dateContent[idx].original)
                )
            }
        },
        me() {
            return this.$store.getters.me
        },
        isNewbie() {
            // return true
            return this.me.contract_id === 2 || this.me.contract_id === 3
        },
        hasProduct() {
            if (!this.isNewbie) return true

            const products = this.me.products
            return products.some(item => item.ptype === 'ticket' && item.enabled === true)
        },
    },
}
</script>
<style scoped lang="scss">
.promise {
    height: calc(100vh - #{$stack-router-header-height});
    overflow-y: auto;

    .section {
        margin-bottom: 48px;
        padding: 0 16px;
        color: black;

        .desc-grey {
            font-size: 12px;
            color: #94999e;
            @include spoqa-f-bold;
        }
        .desc-black {
            font-size: 14px;
            color: #111111;
            @include spoqa-f-regular;
            line-height: 24px;
        }
        .desc-sm {
            font-size: 12px;
            color: black;
            line-height: 1.67;
            @include spoqa-f-regular;

            &.desc-flex {
                display: flex;
            }

            &.c-primary {
                @include spoqa-f-medium;
            }
        }
        .desc-icon {
            border-radius: 12px;
            min-width: 24px;
            min-height: 24px;
            max-width: 24px;
            max-height: 24px;
            padding: 4px;
            background-color: white;
            margin-right: 2px;
        }

        .title {
            display: flex;
            align-items: center;
            font-size: 18px;
            margin-bottom: 8px;
            @include spoqa-f-medium;
        }
        .content {
            .grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 8px;

                &.isBtype {
                    grid-template-columns: repeat(2, 1fr) !important;
                }
            }
            .grey-container {
                background-color: $grey-01;
                padding: 12px 16px 12px 16px;
                border-radius: 8px;
            }

            .item {
                margin-top: 8px;
                padding: 9px 0;
                border: 1px solid $grey-04;
                border-radius: 8px;
                font-size: 14px;
                @include center;

                &.input {
                    padding: 9px;
                    width: 100%;
                    height: 80px;
                }

                &.check-box {
                    height: 48px;
                    justify-content: left;
                    // align-items: left;
                    padding-left: 14px;
                    .check-img {
                        max-width: 20px;
                        max-height: 20px;
                        margin-right: 10px;
                    }
                }

                &.selected {
                    border: 1px solid $blue-primary !important;
                    background-color: #f1fffc;
                    //@include spoqa-f-medium;
                }

                &.date {
                    justify-content: unset;
                    font-size: 14px;
                    color: $grey-06;
                    padding-left: 16px;
                    border: 1px solid $grey-02;

                    .val {
                        color: black;
                    }

                    i {
                        font-size: 16px;
                        color: black;
                        margin-right: 8px;
                    }
                }
                &.selected {
                    color: $blue-primary;
                }
                &.warn {
                    border: 1px solid #f52c2c;
                }
            }
            .warn {
                font-size: 12px;
                line-height: 20px;
                color: #f52c2c;
            }
        }
    }
    ::v-deep .bottom-button {
        border: 1px solid white;
    }
}
</style>
